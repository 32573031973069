import dayjs from 'dayjs';

import DeliveryDate from '@/components/checkDelivery/DeliveryDate/DeliveryDate';
import type { DeliveryInfoModelType } from '@/models/api';

export type DeliveryDetailsType = {
  postalData: DeliveryInfoModelType;
  isDeliveryPopup: boolean;
};

const DeliveryTable: React.FC<DeliveryDetailsType> = ({
  postalData,
  isDeliveryPopup,
}: DeliveryDetailsType) => {
  const { deliveryDetails, cutoffTime } = postalData;
  const cutoffTimehA = dayjs(cutoffTime).format('hA');
  const cutoffDay = dayjs(cutoffTime).format('dddd');
  const cutoffHour =
    cutoffTimehA === '12AM'
      ? `Order by midnight ${cutoffDay} `
      : `Order before ${cutoffTimehA} ${cutoffDay} `;
  return (
    <>
      {isDeliveryPopup ? (
        <div className="m-auto mb-6 max-w-[332px] rounded-lg border border-off-lite">
          <div className="pt-4 pb-5">
            <p className="text-center font-interMedium text-xs leading-tight text-black md:text-sm">
              {cutoffHour}
            </p>
            <p className="text-center font-interMedium text-xs leading-tight text-black md:text-sm">
              for delivery on:
            </p>
          </div>

          <div className="px-1.5">
            {deliveryDetails &&
              deliveryDetails.map((date: any, index: number) => (
                <DeliveryDate
                  key={index}
                  date={date}
                  index={index}
                  isDeliveryPopup={isDeliveryPopup}
                />
              ))}
          </div>
        </div>
      ) : (
        <div>
          <p
            className={`mb-5 border-b border-grey-dark pb-5 font-interMedium text-lg leading-6 lg:text-[21px] lg:leading-7`}
          >
            <span>{cutoffHour}</span>
            <span className="block lg:inline">for delivery on:</span>
          </p>
          {deliveryDetails &&
            deliveryDetails.map((date: any, index: number) => (
              <DeliveryDate
                key={index}
                date={date}
                index={index}
                isDeliveryPopup={isDeliveryPopup}
              />
            ))}
        </div>
      )}
    </>
  );
};

export default DeliveryTable;
