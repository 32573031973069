import _ from 'lodash';
import Link from 'next/link';
import React from 'react';

import type { DeliveryInfoModelType } from '@/models/api';
import { getFreeShippingThreshold } from '@/utils/helpers';

/* TO BE UPDATED FROM CMS */

export type DeliveryNotesType = {
  postalData: DeliveryInfoModelType;
  isDeliveryPopup: boolean;
};

const DeliveryNotes: React.FC<DeliveryNotesType> = ({
  postalData,
  isDeliveryPopup,
}: DeliveryNotesType) => {
  const { fee } = postalData;
  const thresholdDescription = _.get(
    postalData,
    'cms.subscriptionInstruction.thresholdDescription',
    []
  );
  const freeshippingThreshold = getFreeShippingThreshold(thresholdDescription);
  return (
    <>
      {isDeliveryPopup ? (
        <div className="m-auto md:max-w-[80%]">
          <div className="px-9 md:px-0">
            <p className="mt-6 text-center font-inter text-xs leading-4 text-black md:font-interMedium md:text-base md:leading-normal">
              Get{' '}
              <span className="font-interMedium text-success-copy">
                FREE SHIPPING{' '}
              </span>
              on every subscription order valued over ${freeshippingThreshold},
              or pay
              <span className="font-interMedium"> ${fee} </span> per delivery to
              your area.
            </p>
          </div>
        </div>
      ) : (
        <div className="my-8 lg:mt-10 lg:mb-6">
          <p className="font-interMedium lg:text-base">
            We charge ${fee} per delivery. There is an additional charge of $5
            for each extra box.&nbsp;
            <strong className={'font-interMedium'}>
              Unlock FREE SHIPPING when you become a subscriber for orders over
              ${freeshippingThreshold}!
            </strong>
            <Link href="/subscribe-and-save/" legacyBehavior>
              <span className="cursor-pointer pl-1.5 font-interMedium text-attention">
                Find out more.
              </span>
            </Link>
          </p>
        </div>
      )}
    </>
  );
};

export default DeliveryNotes;
