import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { observer } from 'mobx-react-lite';
import Link from 'next/link';
import React from 'react';

import DeliveryHeader from '@/components/checkDelivery/DeliveryHeader/DeliveryHeader';
import DeliveryNotes from '@/components/checkDelivery/DeliveryNotes/DeliveryNotes';
import DeliveryTable from '@/components/checkDelivery/DeliveryTable/DeliveryTable';
import Button from '@/components/common/Button/Button';
import type { DeliveryInfoModelType } from '@/models/api';

import { getTimeZone } from '../../../utils/constants';
import DeliveryTimer from '../DeliveryTimer/DeliveryTimer';

type DeliveryDetailsType = {
  postalData: DeliveryInfoModelType;
  isDeliveryPopup: boolean;
  setShowModal?: (show: boolean) => void;
};

dayjs.extend(utc);
dayjs.extend(timezone);

export const convertTimeZone = ({ timeZone, cutOffDateTime = '' }: any) => {
  dayjs.tz.setDefault(timeZone);
  let formattedDate = dayjs.tz(dayjs(), timeZone).valueOf();
  if (cutOffDateTime !== '') {
    formattedDate = dayjs
      .tz(dayjs(cutOffDateTime).format(), timeZone)
      .valueOf();
  }
  return formattedDate;
};

const isValid4CountDown = (postalData: any) => {
  const cutOffDateTime = postalData.cutoffTime;
  const stateCode = postalData.state;
  const timeZone = getTimeZone(stateCode);
  const cutOffTimeCalendar = convertTimeZone({
    timeZone,
    cutOffDateTime,
  });
  postalData.timeZone = timeZone;
  postalData.cutOffTimeCalendar = cutOffTimeCalendar;
  postalData.isValidForCountdown = false;
  const currentTimeCalendar = convertTimeZone({ timeZone });

  const differenceInMilliseconds = cutOffTimeCalendar - currentTimeCalendar;
  const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);

  if (differenceInHours < 24) {
    postalData.nextDeliveryDate = postalData.deliveryDetails[0]?.formattedDate;
    postalData.isValidForCountdown = true;
  }
  return postalData;
};

const DeliveryDetails: React.FC<DeliveryDetailsType> = observer(
  ({ postalData, isDeliveryPopup, setShowModal }: DeliveryDetailsType) => {
    postalData = isValid4CountDown(postalData);
    return (
      <div
        className={`${
          isDeliveryPopup
            ? 'custom-scroll max-h-[542px] w-[302px] overflow-y-auto rounded-lg border-b-[20px] border-r-0 border-t-[40px] border-solid border-transparent bg-white md:max-h-[612px] md:w-[526px]'
            : ''
        } `}
      >
        <DeliveryHeader
          postalData={postalData}
          isDeliveryPopup={isDeliveryPopup}
        />
        {postalData.isValidForCountdown && isDeliveryPopup && (
          <DeliveryTimer postalData={postalData} />
        )}
        <DeliveryTable
          postalData={postalData}
          isDeliveryPopup={isDeliveryPopup}
        />
        {isDeliveryPopup && setShowModal && (
          <div className="mt-4 flex justify-center">
            <Button onClick={() => setShowModal(false)} type="button">
              <span className="py-1 font-interMedium leading-none">
                Continue Ordering
              </span>
            </Button>
          </div>
        )}
        <DeliveryNotes
          isDeliveryPopup={isDeliveryPopup}
          postalData={postalData}
        />
        {!isDeliveryPopup && (
          <div className="flex justify-center md:justify-start">
            <Link href="/menu/meals" legacyBehavior>
              <Button
                type="button"
                className="!px-6 !py-3 font-interMedium !text-base leading-none"
              >
                <span>Order Now</span>
              </Button>
            </Link>
          </div>
        )}
      </div>
    );
  }
);

export default DeliveryDetails;
