import type { DeliveryDetailModelType } from '@/models/api';

type DeliveryDatesType = {
  date: DeliveryDetailModelType;
  index: number;
  isDeliveryPopup: boolean;
};

const DeliveryDate: React.FC<DeliveryDatesType> = ({
  date,
  index,
  isDeliveryPopup,
}: DeliveryDatesType) => {
  const rowClass = index % 2 ? 'bg-off-grey rounded' : 'bg-white';
  return (
    <div
      className={`mb-[18px] flex flex-row justify-between font-interMedium lg:mb-5 ${
        isDeliveryPopup
          ? `${rowClass} text-xs lg:text-sm`
          : 'text-base leading-[32px] lg:text-lg'
      }`}
    >
      <div className="px-1.5">{date?.formattedDate}</div>
      <div className="px-1.5">
        {date && date?.timeslots?.join(', ').replace(/\s&\s/g, '-')}
      </div>
    </div>
  );
};

export default DeliveryDate;
