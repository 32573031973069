import Image from 'next/legacy/image';
import React, { useEffect } from 'react';

import type { DeliveryInfoModelType } from '@/models/api';

export type DeliveryHeaderType = {
  postalData: DeliveryInfoModelType;
  isDeliveryPopup: boolean;
};

const DeliveryHeader: React.FC<DeliveryHeaderType> = ({
  postalData,
  isDeliveryPopup,
}: DeliveryHeaderType) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col items-center">
      {isDeliveryPopup && (
        <>
          <div className="block md:hidden">
            <Image
              src="/assets/images/svg/delivery.svg"
              alt=""
              width="68"
              height="45"
            />
          </div>
          <div className="hidden md:block">
            <Image
              src="/assets/images/svg/delivery.svg"
              alt=""
              width="94"
              height="62"
            />
          </div>
        </>
      )}

      {isDeliveryPopup ? (
        <h2 className="my-6 flex flex-col items-center md:mt-8">
          <span
            className={`font-interMedium text-base leading-snug text-black md:font-interMedium md:text-3xl md:leading-tight`}
          >
            Yes, we deliver to
          </span>
          <span className="font-interMedium text-base leading-snug text-black md:text-3xl md:leading-tight">
            {postalData.name}, {postalData.state}, {postalData.postcode}!
          </span>
        </h2>
      ) : (
        <h2 className="mb-6 font-interMedium text-[32px] leading-10 lg:text-[44px] lg:leading-[50px]">
          Yes, we deliver<br></br> to {postalData.name}, {postalData.state}
          &nbsp;{postalData.postcode}!
        </h2>
      )}
    </div>
  );
};

export default DeliveryHeader;
