import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { useState } from 'react';
import Countdown, { zeroPad } from 'react-countdown';

import type { DeliveryInfoModelType } from '@/models/api';

dayjs.extend(relativeTime);

type DeliveryTimerType = {
  postalData: DeliveryInfoModelType;
};

const renderer = ({ hours, minutes, seconds, completed }: any) => {
  if (completed) {
    const Completion = () => <span>You are good to go!</span>;
    // Render a completed state
    return <Completion />;
  }
  // Render a countdown
  return (
    <div>
      <div className="flex flex-row items-center justify-center font-interMedium text-base text-black">
        <div className="mx-1 mt-1 rounded-md border border-grey-dark bg-off-grey p-1.5 py-1 leading-none">
          {zeroPad(hours).toString()[0]}
        </div>
        <div className="mx-1 mt-1 rounded-md border border-grey-dark bg-off-grey p-1.5 py-1 leading-none">
          {zeroPad(hours).toString()[1]}
        </div>
        <span className="pt-2 text-3xl text-grey-dark">:</span>
        <div className="mx-1 mt-1 rounded-md border border-grey-dark bg-off-grey p-1.5 py-1 leading-none">
          {zeroPad(minutes).toString()[0]}
        </div>
        <div className="mx-1 mt-1 rounded-md border border-grey-dark bg-off-grey p-1.5 py-1 leading-none">
          {zeroPad(minutes).toString()[1]}
        </div>
        <span className="pt-2 text-3xl text-grey-dark">:</span>
        <div className="mx-1 mt-1 rounded-md border border-grey-dark bg-off-grey p-1.5 py-1 leading-none">
          {zeroPad(seconds).toString()[0]}
        </div>
        <div className="mx-1 mt-1 rounded-md border border-grey-dark bg-off-grey p-1.5 py-1 leading-none">
          {zeroPad(seconds).toString()[1]}
        </div>
      </div>
      <div className="flex flex-row items-center justify-center text-center font-interMedium text-xxs text-grey-dark">
        <div className="mx-3 w-10">HRS</div>
        <div className="mx-3 w-10">MIN</div>
        <div className="mx-3 w-10 pl-2">SEC</div>
      </div>
    </div>
  );
};

const DeliveryTimer: React.FC<DeliveryTimerType> = ({
  postalData,
}: DeliveryTimerType) => {
  const { cutoffTime, deliveryDetails } = postalData;
  const date1 = dayjs(cutoffTime);
  const date2 = dayjs();

  const hours = date1.diff(date2, 'hours');
  const days = Math.floor(hours / 24);
  const [showTimer, setShowTimer] = useState(true);

  const nextDeliveryDate = deliveryDetails && deliveryDetails[0]?.date;

  return (
    <>
      {days === 0 && showTimer && (
        <div className="m-auto mx-1 mb-6 max-w-[332px] rounded-lg border border-grey-disable px-2 py-3 md:mx-auto">
          <p className="pb-3 text-center font-inter text-sm text-black">
            Time left to order and receive delivery
            <span className="block font-interMedium text-sm text-success-copy">
              {dayjs(nextDeliveryDate).format('ddd, D MMM')}:
            </span>
          </p>
          <Countdown
            date={dayjs(cutoffTime).toDate()}
            renderer={renderer}
            onComplete={() => setShowTimer(false)}
          />
        </div>
      )}
    </>
  );
};

export default DeliveryTimer;
